const burgerToggleRef = document.getElementById('burger-toggle') as HTMLInputElement | null;
const mainHeaderRef = document.getElementById('main-header');

const handleToggleChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (mainHeaderRef && target.checked) {
    mainHeaderRef.classList.remove('-top-12');
    mainHeaderRef.classList.add('top-0');
  }
};

const handleScroll = () => {
  if (mainHeaderRef && !burgerToggleRef?.checked) {
    if (window.scrollY > 150) {
      mainHeaderRef.classList.remove('top-0');
      mainHeaderRef.classList.add('-top-12');
    } else {
      mainHeaderRef.classList.remove('-top-12');
      mainHeaderRef.classList.add('top-0');
    }
  }
};

window.addEventListener('scroll', handleScroll);
if (burgerToggleRef) burgerToggleRef.addEventListener('change', handleToggleChange);
